import React, { useEffect, useState } from 'react';
import Title from '../../components/Title';
import CME from './../../assets/Photos/CriticalMineralExploration.png';
import secondImage from '../../assets/Photos/service-2.png';
import thirdImage from '../../assets/Photos/service-3.png';
import fourthImage from '../../assets/Photos/service-4.png';
import fifthImage from '../../assets/Photos/service-5.png';
import sixthImage from '../../assets/Photos/service-6.png';
import sevenImage from '../../assets/Photos/service-7.png';
import firstImage from '../../assets/Photos/service-1.png';
import PageHeading from '../../components/PageHeading/PageHeading';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Popover from '../../components/Popover';
import DropdownIcon from '../../assets/Icons/DropdownArrow';

function Services() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation(); // Use the useLocation hook
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

  const services = [
    'Critical Mineral Exploration',
    'Deep Seated Mineral Exploration',
    'Hydrocarbon Exploration',
    'Ground Water Exploration',
    'Environmental Mapping',
    'Early Stage Exploration Advisory',
  ];

  const servicesPictures = [
    firstImage,
    secondImage,
    thirdImage,
    fourthImage,
    fifthImage,
    sixthImage,
    sevenImage,
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const serviceIndex = params.get('service');

    if (serviceIndex) {
      const index = parseInt(serviceIndex, 10); // Convert to number
      if (!isNaN(index) && index >= 0 && index < services.length) {
        setActiveIndex(index); // Set the activeIndex if the index is valid
      }
    }
  }, [location.search]);

  const servicesDescription = [
    <div className="font-semibold pt-4 pb-4">
      <span>
        Critical minerals are those minerals that are essential for economic
        development and national security, the lack of availability of these
        minerals or concentration of extraction or processing in a few
        geographical locations may lead to supply chain vulnerabilities and even
        disruption of supplies.
      </span>
      <img
        src={
          servicesPictures[activeIndex]
            ? servicesPictures[activeIndex]
            : servicesPictures[0]
        }
        className={`mt-4 mb-4 p-1  bg-white rounded-md services-img-common services-img-${activeIndex}`}
      />
      <span>What is the Significance of Critical Minerals for India?</span>
      <br />
      <span>Economic Development :</span>
      <ul className="list-disc list-inside font-medium">
        <li>
          Industries such as high-tech electronics, telecommunications,
          transport, and defense heavily rely on these minerals.
        </li>
        <li>
          Given India's significant domestic demand and potential in these
          sectors, their growth can lead to job creation, income generation, and
          innovation.
        </li>
      </ul>
      <span>National Security :</span>
      <ul className="list-disc list-inside font-medium">
        <li>
          These minerals are vital for defense, aerospace, nuclear, and space
          applications, necessitating the use of high-quality and reliable
          materials capable of withstanding extreme conditions and performing
          complex functions.
        </li>
      </ul>
      <span>Environmental Sustainability :</span>
      <ul className="list-disc list-inside font-medium">
        <li>
          They are integral to the transition toward clean energy and a
          low-carbon economy, enabling the reduction of India's reliance on
          fossil fuels and greenhouse gas emissions.
        </li>
        <li>
          With a commitment to attaining 450 GW of renewable energy capacity by
          2030, these minerals are essential for achieving India's green
          objectives.
        </li>
      </ul>
    </div>,
    <div className="font-semibold pt-4 pb-4">
      <span>
        Deep-seated minerals refer to mineral deposits located deep within the
        Earth's crust, unlike surficial minerals that are found near the
        surface. Common examples include gold, silver, copper, zinc, lead,
        nickel, cobalt, platinum group minerals, and diamonds.
      </span>
      <img
        src={
          servicesPictures[activeIndex]
            ? servicesPictures[activeIndex]
            : servicesPictures[0]
        }
        className={`mt-4 mb-4 p-1  bg-white  rounded-md services-img-common services-img-${activeIndex}`}
      />
      <span>
        The exploration and extraction of deep-seated minerals are challenging
        due to their depth and location. This process often requires advanced
        technology and significant investment in drilling and mining equipment.
        The deeper the mineral deposits, the more complex and costly it is to
        access them, requiring more energy, sophisticated techniques, and safety
        measures.
      </span>
    </div>,
    <div className="font-semibold pt-4 pb-4">
      <span>
        Hydrocarbon exploration (oil and gas) is the search by petroleum
        geologists and geophysicists for deposits of hydrocarbons, particularly
        petroleum and natural gas, inside the Earth’s Crust, using petroleum
        geology.
      </span>
      <img
        src={
          servicesPictures[activeIndex]
            ? servicesPictures[activeIndex]
            : servicesPictures[0]
        }
        className={`mt-4 mb-4 p-1  bg-white rounded-md services-img-common services-img-${activeIndex}`}
      />
      <span>
        Gravity gradiometers are devices that measure all the independent tensor
        components of the gravity gradient field from a moving platform,
        allowing for accurate data acquisition to improve mineral and
        hydrocarbon exploration results.
      </span>
    </div>,
    <div className="font-semibold pt-4 pb-4">
      <span>
        In the last 30 years, water resource management challenges have prompted
        the application of AEM methods to basin- or watershed-scale mapping.
      </span>
      <img
        src={
          servicesPictures[activeIndex]
            ? servicesPictures[activeIndex]
            : servicesPictures[0]
        }
        className={`mt-4 mb-4 p-1  bg-white rounded-md services-img-common services-img-${activeIndex}`}
      />
      <span>
        This increased usage of AEM to map water resources can be attributed to:
      </span>
      <ol className="list-decimal pl-6">
        <li>
          Applicability of airborne methods to map electrical properties of the
          earth for geological interpretation
        </li>
        <li>
          Advancements in both AEM hardware and software that enable detection
          and interpretation of aquifer materials
        </li>
        <li>
          The need for sustainable management of water resources due to
          increasing population, expanding areas of irrigated agriculture,
          economic development, and the ever‐increasing demand for water
          worldwide
        </li>
      </ol>
    </div>,
    <div className="font-semibold pt-4 pb-4">
      <span>
        Geophysical exploration of geothermal resources deals with measurements
        on the physical properties of the earth.
      </span>
      <img
        src={
          servicesPictures[activeIndex]
            ? servicesPictures[activeIndex]
            : servicesPictures[0]
        }
        className={`mt-4 mb-2 p-1 rounded-md services-img-common services-img-${activeIndex}`}
      />
      <span>
        The term environment mapping describes a texturing technique used to
        model some of the influences of the surrounding environment on an
        object's appearance. Environment mapping, like regular surface
        texturing, changes an object's appearance by applying a texture map to
        its surface.
      </span>
    </div>,
    <div className="font-semibold pt-4 pb-4">
      <span>
        Geoscientific data is collected to help build more meaningful models of
        the earth. These models help form our perception of reality and the
        foundation upon which we make our decisions. As an industry we spend a
        great deal of time and effort collecting enormous amounts of data.{' '}
      </span>
      <img
        src={
          servicesPictures[activeIndex]
            ? servicesPictures[activeIndex]
            : servicesPictures[0]
        }
        className={`mt-4 mb-4 p-1 rounded-md services-img-common services-img-${activeIndex}`}
      />
      <p>
        Traditional methods of analysing and interpreting these datasets are
        usually too slow and inefficient. Consequently, geoscientists often feel
        frustration when the models developed are only based upon very small
        portions of the data collected.
      </p>
      <p>
        To address this challenge AGI has partnered with independent inventors
        and small research groups that share this frustration and have
        recognised the need for new solutions. In addition to addressing many of
        the limitations of traditional approaches, the efficiency and insights
        revealed with these new solutions is something we would like to share
        and apply to your technical challenges.
      </p>
      <img
        src={
          servicesPictures[activeIndex + 1]
            ? servicesPictures[activeIndex + 1]
            : servicesPictures[0]
        }
        className={`mt-4 mb-4 p-1 rounded-md services-img-common services-img-${activeIndex}`}
      />
      <p>
        AGI assists Governments and private corporates secure international
        exploration and frontier acreage, particularly in neglected and over
        looked areas throughout the world and through the analysis of vintage
        data sets; providing new prospectivity analysis.
      </p>
    </div>,
  ];

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className="mb-12">
      <div className="md:hidden w-fit mx-auto mt-12 bg-white bg-opacity-30 p-2 text-[12px] rounded-lg">
        <Popover
          isVisible={popoverVisible}
          setIsVisible={setPopoverVisible}
          content={
            <div>
              {services.map((tech, index) => (
                <Link
                  key={index}
                  to={`/services?service=${encodeURIComponent(index)}`}
                  className="block p-2 hover:bg-gray-100"
                  onClick={() => setPopoverVisible(false)} // Close popover on item click
                >
                  {tech}
                </Link>
              ))}
            </div>
          }
        >
          <div className="flex gap-1 justify-center items-center">
            <span
              className={
                location.pathname === '/services'
                  ? 'nav-active hover:text-white-600 text-navbar-title text-[16px]'
                  : 'nav-inactive text-navbar-title hover:text-secondary-light text-[12px]'
              }
              onClick={() => setPopoverVisible(!popoverVisible)} // Toggle visibility
            >
              Our Services
            </span>
            <DropdownIcon
              color="black"
              opacity={
                location.pathname === '/our-technologies' ? '' : 'opacity-60'
              }
            />
          </div>
        </Popover>
      </div>
      <div className="mx-6 md:mx-24 mt-4 md:mt-12 flex flex-row gap-20">
        <div className="hidden md:flex flex-col md:w-[20%]">
          <div>
            {services.map((technique, index) => (
              <div
                key={index}
                onClick={() => handleItemClick(index)}
                className={`py-4 px-4 rounded-md max-w-66 mt-2 cursor-pointer font-medium bg-white text-primary border-primary border-2 ${
                  activeIndex === index
                    ? 'bg-white text-primary border-primary border-2' // Active state: white background, black text
                    : 'bg-white bg-opacity-30 text-primary border-white border-2' // Default state
                }`}
              >
                {technique}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col md:w-[80%]">
          <div>
            <Title
              text={services[activeIndex || 0]}
              weight="font-bold"
              size={2}
            />
          </div>
          <div>{servicesDescription[activeIndex]}</div>
        </div>
      </div>
    </div>
  );
}

export default Services;
