import React from 'react';
import './style.css';
import HeroImage from '../../assets/Photos/HeroImage.png';

const HeroSection = () => {
  return (
    <section className="hero-section flex flex-col md:flex-row mt-8">
      <div className="hero-content">
        <h1 className="font-medium text-lg md:text-4xl md:leading-relaxed hero-title">
          India's first integrated airborne and early-stage exploration company,
          with a global footprint. AGI has a global mandate, the large and
          untapped markets in India in particular and Asia in general remains
          the key focus of the business.
        </h1>
      </div>
      <div>
        {/* Carousel component or images */}
        <img src={HeroImage} alt="Exploration Aircraft" height={20} />
      </div>
    </section>
  );
};

export default HeroSection;
